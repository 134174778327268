import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>Welcome! <span role={"img"} aria-label={"jsx-a11y/accessible-emoji"}>😀</span></h1>
            </header>
            <div className="App-body">
                <div>
                    <img src={"dog.png"} alt={"A very cute dog"}/>
                </div>
                    <p>
                        My name is Charis Schille. I like to spend my time coding, listening to music and playing video
                        games. I'm currently taking a Bachelor's degree in Computer Science at Østfold University
                        College.
                    </p>

            </div>

            <footer className={"App-footer"}>
                <a className={"App-link"} href={"https://gitlab.com/haros"}>Gitlab - @haros</a>
                <a className={"App-link"} href={"https://github.com/haros-dev"}>Github - @haros-dev</a>
                <a className={"App-link"} href={"https://www.linkedin.com/in/charis-schille/"}>LinkedIn -
                    @charis-schille</a>
                <a className={"App-link"} href={"mailto:kleopi.dev@gmail.com"}>Email - kleopi.dev@gmail.com</a>
            </footer>
        </div>
    );
}

export default App;
